
import { defineComponent } from "vue";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import CustomAccordion from "@/components/UI/CustomAccordion.vue";
import AccordionTab from "primevue/accordiontab";
import Utils from "@/utility/utils";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Coupons",
  components: {
    Card,
    InputText,
    Divider,
    CustomAccordion,
    AccordionTab,
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
    }),
    getTotalDiscounts(): string {
      return Utils.reduceTotal(
        this.currentOrder.coupon_id_items,
        "coup_disc_amt",
      ).toFixed(2);
    },
    getShippedAmount(): string {
      return Utils.reduceTotal(
        this.currentOrder.coupon_id_items,
        "coup_disc_amt",
      ).toFixed(2);
    },
  },
  data: () => ({
    currentId: "",
    currentOrder: {} as any,
  }),
  created() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
  mounted() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
});
